<template>
  <ul
    class="list"
    :class="{
      'list-none': withoutDots,
      'medium-rounded': rounded === 'medium',
    }"
    :style="{ backgroundColor: bgColor }"
  >
    <li
      v-for="(value, key) in tabs"
      :key="key"
      class="item"
      :class="{
        active: key === activeTab || activeTab.includes(key),
      }"
      style="flex: 1 1 auto"
      :style="{
        minWidth: itemMinWidth,
      }"
      @click="$emit('change:tab', key)"
    >
      {{ (value && value.title) || value }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "toggleTabs",
  props: {
    tabs: {
      type: Object,
      default: () => {},
    },
    activeTab: {
      required: true,
    },
    withoutDots: {
      type: Boolean,
    },
    rounded: {
      type: String,
      default: () => "full",
    },
    bgColor: {
      type: String,
      default: () => "bg-gray-50",
    },
    itemMinWidth: {
      type: String,
      default: () => "120px",
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  max-width: 600px;
  @apply list-none m-0 flex  rounded p-0.5 bg-gray-200 items-center justify-between gap-x-1;
  .item {
    @apply py-2 text-center text-xs text-gray-500 rounded cursor-pointer font-medium hover:bg-gray-200;
    &.active {
      @apply bg-white text-blue-500 font-medium;
    }
  }

  &.medium-rounded {
    @apply rounded-md;

    .item {
      @apply rounded-md mr-1;
    }
  }
}
</style>
