<script>
export default {
  name: "AnnotatorsReportsList",
  props: {
    annotatorsList: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>

<template>
  <el-table
    :data="annotatorsList"
    v-loading="loading"
    border
    height="800"
    style="width: 100%; overflow-y: auto; margin-top: 20px"
  >
    <el-table-column
      prop="display_name"
      label="Аннотатор"
      min-width="240"
      fixed="left"
    ></el-table-column>
    <el-table-column
      prop="count_status_verified"
      label="Верифицировано файлов"
      width="220"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="total_duration_status_verified"
      label="Верифицировано часов"
      width="220"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="count"
      label="Размечено файлов"
      width="180"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="total_duration"
      label="Размечено часов"
      width="150"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="total_spent"
      label="Затрачено часов"
      width="150"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="rtf"
      label="RTF"
      width="150"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="verified"
      label="Верифицировано %"
      width="180"
      align="center"
    ></el-table-column>
  </el-table>
</template>

<style scoped lang="scss"></style>
