<script>
export default {
  name: "MarkedFilesReportsList",
  props: {
    annotatedFiles: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>

<template>
  <el-table
    :data="annotatedFiles"
    v-loading="loading"
    border
    height="800"
    style="width: 100%; margin-top: 20px; overflow-y: auto"
  >
    <el-table-column
      prop="_id"
      label="ID"
      width="240"
      fixed="left"
    ></el-table-column>
    <el-table-column
      prop="display_name"
      label="Аннотатор"
      min-width="240"
    ></el-table-column>
    <el-table-column
      prop="topic"
      label="Имя батча"
      min-width="200"
    ></el-table-column>
    <el-table-column
      prop="filename"
      label="Имя файла"
      min-width="240"
    ></el-table-column>
    <el-table-column
      prop="duration_utterances"
      label="Длительность фрагментов"
      min-width="240"
    ></el-table-column>
    <el-table-column prop="lang" label="Язык" width="150"></el-table-column>
    <el-table-column
      prop="annotator_finish"
      label="Дата завершения разметки"
      min-width="240"
    ></el-table-column>
    <el-table-column
      prop="status"
      label="Статус"
      min-width="240"
    ></el-table-column>
  </el-table>
</template>

<style scoped lang="scss"></style>
