<script>
import ExportIcon from "@/icons/ExportIcon.vue";
import { getUsers } from "@/api/users";
import apiClient from "@/api/api-client";
import ToggleTabs from "@/views/manage/toggleTabs.vue";
import SummaryReportsList from "@/views/manage/Reports/SummaryReportsList.vue";
import AnnotatorsReportsList from "@/views/manage/Reports/AnnotatorsReportsList.vue";
import MarkedFilesReportsList from "@/views/manage/Reports/MarkedFilesReportsList.vue";

export default {
  name: "Reports.vue",
  components: {
    MarkedFilesReportsList,
    AnnotatorsReportsList,
    SummaryReportsList,
    ToggleTabs,
    ExportIcon,
  },
  data() {
    return {
      activeTab: "summary",
      activeName: "summaryTab",
      annotatorsDate: null,
      markedFilesDate: null,
      selectedAnnotator: null,
      users: [],
      loading: false,
      summaryList: [],
      annotatorsList: [],
      annotatedFiles: [],
    };
  },
  computed: {
    domain() {
      return "https://dev.tr.q19.kz";
    },
    Tabs() {
      return {
        summary: "Сводный",
        annotators: "Аннотаторы",
        markedFiles: "Размеченные файлы",
      };
    },
  },
  async created() {
    await this.getSummaryList();
  },
  methods: {
    async downloadReport(endpoint, params = {}) {
      this.loading = true;
      try {
        const data = await apiClient.post(endpoint, params);

        if (data && data.data && data.data.output_path) {
          const fileUrl = `${this.domain}${data.data.output_path}`;
          this.downloadFile(data.data.file_name, fileUrl);
        } else {
          alert("Не успешно");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async downloadSummaryReport() {
      await this.downloadReport("/export/summary");
    },

    async downloadAnnotatorsReport() {
      const params =
        this.annotatorsDate && this.annotatorsDate[0] && this.annotatorsDate[1]
          ? {
              start_date: this.annotatorsDate[0],
              stop_date: this.annotatorsDate[1],
            }
          : {};
      await this.downloadReport("/export/annotator", params);
    },

    async downloadMarkedFiles() {
      const params =
        this.markedFilesDate &&
        this.markedFilesDate[0] &&
        this.markedFilesDate[1]
          ? {
              start_date: this.markedFilesDate[0],
              stop_date: this.markedFilesDate[1],
              ...(this.selectedAnnotator && {
                annotator_id: this.selectedAnnotator,
              }),
            }
          : this.selectedAnnotator
          ? { annotator_id: this.selectedAnnotator }
          : {};

      await this.downloadReport("/export/annotated_files", params);
    },
    downloadFile(filename, fileUrl) {
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async changeActiveTab(key) {
      this.activeTab = key;
      if (this.activeTab === "summary") {
        await this.getSummaryList();
      }
      if (this.activeTab === "annotators") {
        await this.getAnnotators();
      }
      if (this.activeTab === "markedFiles") {
        await this.getAnnotatedFiles();
        this.users = (await getUsers()) || [];
      }
    },
    async getSummaryList() {
      this.loading = true;
      try {
        const response = await apiClient.get("/export/summary");
        if (response && response.data) {
          this.summaryList = response.data.data || [];
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getAnnotators() {
      this.loading = true;
      try {
        const response = await apiClient.get("/export/annotator");
        if (response && response.data) {
          this.annotatorsList = response.data.data || [];
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getAnnotatedFiles() {
      this.loading = true;
      try {
        const response = await apiClient.get("/export/annotated_files");
        if (response && response.data) {
          this.annotatedFiles = response.data.data || [];
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <div class="h-full">
    <nav class="h-full">
      <h1
        class="text-3xl font-semibold p-6 border-0 border-b border-solid mb-6"
      >
        Отчеты
      </h1>
      <toggle-tabs
        style="margin-left: 24px; margin-bottom: 40px"
        :active-tab="activeTab"
        :tabs="Tabs"
        @change:tab="changeActiveTab"
      ></toggle-tabs>
      <div style="margin-left: 24px">
        <div v-if="activeTab === 'summary'" class="flex flex-col w-full pr-6">
          <div class="flex w-full justify-end mt-0">
            <el-button
              @click="downloadSummaryReport"
              :disabled="loading"
              type="success"
              size="large"
              plain
              style="width: 140px"
              class="download_button"
            >
              <export-icon></export-icon>
              Скачать xlsx</el-button
            >
          </div>
          <SummaryReportsList :summary-list="summaryList" :loading="loading" />
        </div>
        <div
          v-if="activeTab === 'annotators'"
          class="flex flex-col w-full pr-6"
        >
          <div class="w-full flex justify-end gap-2">
            <el-date-picker
              v-model="annotatorsDate"
              value-format="YYYY-MM-DD"
              type="daterange"
              size="large"
              placeholder="Дата"
              style="max-width: 240px; margin-right: 10px"
            ></el-date-picker>
            <el-button
              @click="downloadAnnotatorsReport"
              :disabled="loading"
              type="success"
              size="large"
              plain
              style="width: 140px"
              class="download_button"
            >
              <export-icon></export-icon>
              Скачать xlsx</el-button
            >
          </div>
          <AnnotatorsReportsList
            :annotators-list="annotatorsList"
            :loading="loading"
          />
        </div>
        <div
          v-if="activeTab === 'markedFiles'"
          class="flex flex-col w-full pr-6"
        >
          <div class="flex w-full justify-end gap-3">
            <el-date-picker
              v-model="markedFilesDate"
              value-format="YYYY-MM-DD"
              type="daterange"
              size="large"
              placeholder="Дата"
              style="max-width: 240px"
            ></el-date-picker>
            <el-select
              v-model="selectedAnnotator"
              clearable
              filterable
              placeholder="Аннотатор"
              size="large"
              style="width: 240px"
            >
              <el-option
                v-for="user in users"
                :key="user._id"
                :value="user._id"
                :label="`${user.first_name} ${user.last_name}`"
              ></el-option>
            </el-select>
            <el-button
              @click="downloadMarkedFiles"
              :disabled="loading"
              type="success"
              size="large"
              plain
              style="width: 140px"
              class="download_button"
            >
              <export-icon></export-icon>
              Скачать xlsx</el-button
            >
          </div>
          <MarkedFilesReportsList
            :annotated-files="annotatedFiles"
            :loading="loading"
          />
        </div>
      </div>
    </nav>
  </div>
</template>

<style scoped lang="scss">
.clear_button {
  font-size: 11.5px;
  font-weight: 400;
}
.download_button {
  font-size: 11.5px;
  font-weight: 400;
}
.download_button:hover {
  svg {
    fill: #fff;
  }
}
::v-deep(.el-table__header th) {
  @apply bg-gray-50 text-gray-500;
}
</style>
