<script>
export default {
  name: "ExportIcon",
};
</script>

<template>
  <svg
    fill="rgb(103, 194, 58)"
    viewBox="0 0 50 50"
    style="width: 12px; height: 12px; margin: -1px 5px -1px 0"
  >
    <path
      d="M29 0L1 5 0 6v38l1 1 28 5a1 1 0 0 0 1-1v-5h17l2-2V8l-2-2H30V1 0h-1zm-1 2v46L2 43V7zm2 6h17v34H30v-5h4v-2h-4v-6h4v-2h-4v-5h4v-2h-4v-5h4v-2h-4zm6 5v2h8v-2zM7 16l5 9-6 9h5l3-6 1-1v1l4 6h5l-6-9 5-9h-4l-3 5-1 2v-2l-3-5zm29 4v2h8v-2zm0 7v2h8v-2zm0 8v2h8v-2z"
    ></path>
  </svg>
</template>

<style scoped lang="scss"></style>
