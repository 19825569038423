<script>
export default {
  name: "SummaryReportsList",
  props: {
    summaryList: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      statusTitles: {
        0: "Новые",
        1: "В работе",
        2: "Размеченные",
        3: "На верификации",
        4: "Верифицированные",
      },
    };
  },
  computed: {
    uniqueStatuses() {
      const statusSet = new Set();
      this.summaryList.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (!isNaN(key)) {
            statusSet.add(key);
          }
        });
      });
      return Array.from(statusSet).sort();
    },
    filteredStatuses() {
      return this.uniqueStatuses.filter((status) =>
        ["0", "1", "2", "3", "4"].includes(status)
      );
    },
  },
};
</script>

<template>
  <el-table
    :data="summaryList"
    v-loading="loading"
    border
    size="large"
    height="800"
    style="width: 100%; margin-top: 20px; overflow-y: auto"
  >
    <el-table-column
      prop="batch_name"
      label="Имя батча"
      width="250"
      fixed="left"
    ></el-table-column>
    <el-table-column
      prop="created_at"
      label="Создано"
      width="180"
      align="center"
    ></el-table-column>
    <template v-for="status in filteredStatuses" :key="status">
      <el-table-column :label="statusTitles[status]" min-width="270">
        <template #default="{ row }">
          <div v-if="row[status]">
            <div class="flex items-center">
              Файлы:
              <div class="font-semibold text-black ml-1">
                {{ row[status].count }}
              </div>
            </div>
            <div class="flex items-center">
              Фрагменты:
              <div class="font-semibold text-black ml-1">
                {{ row[status].total_utterances }}
              </div>
            </div>
            <div class="flex items-center">
              Длительность (по часам):
              <div class="font-semibold text-black ml-1">
                {{ row[status].dur }}
              </div>
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
    </template>
  </el-table>
</template>

<style scoped lang="scss"></style>
